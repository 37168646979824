<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";
import {swalHelper} from "@/helpers/swal-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";


/**
 * Information Bars Component
 */
export default {

  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,
      languageId: "",

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        isBusy: false,

        items: []
      },

      modals: {
        informationBar: {
          visible: false,

          form: {
            message: "",
            orderValue: 0,
            backgroundColorHex: "#FF0000",
            textColorHex: "#FFFFFF",
            startTime: "",
            endTime: ""
          }
        }
      }

    };
  },

  validations: {
    modals: {
      informationBar: {
        form: {
          message: {required},
          orderValue: {required},
          backgroundColorHex: {required},
          textColorHex: {required},
          startTime: {required},
          endTime: {required}
        }
      }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Paski informacji",
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "orderValue", label: "Kolejność wyświetlania"},
        {key: "message", label: "Wiadomość"},
        {key: "backgroundColorHex", slot: true, label: "Kolor tła"},
        {key: "textColorHex", slot: true, label: "Kolor tekstu"},
        {key: "deleted", slot: true, label: "Usunięte"},
        {key: "startTime", label: "Data rozpoczęcia", formatter: value => dateUtil.asDateTime(value)},
        {key: "endTime", label: "Data zakończenia", formatter: value => dateUtil.asDateTime(value)},
        {key: "createdAt", label: "Data utworzenia", formatter: value => dateUtil.asDateTime(value)},
        {key: "updatedAt", label: "Data aktualizacji", formatter: value => dateUtil.asDateTime(value)},
        {key: "deletedAt", label: "Data usunięcia", formatter: value => dateUtil.asDateTime(value)},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadInformationBars() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/information-bar/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            languageId: this.languageId
          }
        });

        const {informationBars, count} = data

        this.table.items = informationBars
        this.table.totalRows = count
        this.table.rows = count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items;
    },

    openInformationBarModal(informationBar) {
      if (informationBar) {
        this.modals.informationBar.form = Object.assign({}, informationBar)
        this.modals.informationBar.form.startTime = this.formatDateTime(this.modals.informationBar.form.startTime);
        this.modals.informationBar.form.endTime = this.formatDateTime(this.modals.informationBar.form.endTime);
      }

      this.modals.informationBar.visible = true
    },

    hideInformationBarModal() {
      this.modals.informationBar.visible = false
      this.modals.informationBar.form = {
        message: "",
        orderValue: 0,
        backgroundColorHex: "#FF0000",
        textColorHex: "#FFFFFF",
        startTime: "",
        endTime: ""
      }
    },

    formatDateTime(dateTimeISO) {
      const dateTime = new Date(dateTimeISO);
      return new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 16);
    },

    addTimezoneOffset(dateTime) {
      const offset = new Date().getTimezoneOffset();
      const sign = offset > 0 ? "-" : "+";
      const hours = Math.abs(Math.floor(offset / 60)).toString().padStart(2, "0");
      const minutes = Math.abs(offset % 60).toString().padStart(2, "0");

      return `${dateTime}${sign}${hours}:${minutes}`;
    },

    createOrEditInformationBar() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.modals.informationBar.form.$touch();
      if (this.$v.modals.informationBar.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        languageId: this.languageId,
        ...this.modals.informationBar.form,
        startTime: this.addTimezoneOffset(this.modals.informationBar.form.startTime),
        endTime: this.addTimezoneOffset(this.modals.informationBar.form.endTime)
      });

      axios.put(`/information-bar`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$root.$emit("ecat-reload-information-bar")
        this.hideInformationBarModal()
        Swal.fire("Sukces!", this.modals.informationBar.form.id ? "Zaktualizowano pasek informacji" : "Dodano nowy pasek informacji", "success")
            .then(() => this.$refs.table.refresh())
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteInformationBar(informationBar) {
      swalHelper.yesOrNoCustomizable(() => {
        axios.delete(`/information-bar/${informationBar.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        }).then(() => {
          Swal.fire("Sukces!", "Pasek informacji został usunięty!", "success")
              .then(() => this.$refs.table.refresh());
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, "warning", "Czy na pewno?", "Pasek informacji zostanie usunięty", "Usuń", "Anuluj", "btn btn-danger", "btn btn-secondary")
    }
  },

  async created() {
    const languageId = this.$route.params.languageId;
    if (!languageId) {
      await this.$router.push('/dashboard/home').then(() => {
        Swal.fire("", "Nie znaleziono takiego języka!", "error");
      }).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
      return;
    }

    this.languageId = languageId
  }

};
</script>

<template>
  <Layout>
    <PageHeader title="Paski informacji" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">

                <b-button variant="success" @click="openInformationBarModal(null)">Dodaj nowy pasek informacji
                </b-button>
              </div>
            </div>

            <ecat-table
                ref="table"
                :table="table"
                :fields="getFields()"
                :items="loadInformationBars"
                :pagination-top="true"
            >
              <template v-slot:backgroundColorHex="{ item }">
                <div class="rounded-circle information-bar-circle"
                     :style="`background-color: ${item.backgroundColorHex}`"/>
                <span>({{ item.backgroundColorHex }})</span>
              </template>

              <template v-slot:textColorHex="{ item }">
                <div class="rounded-circle information-bar-circle"
                     :style="`background-color: ${item.textColorHex}`"/>
                <span>({{ item.textColorHex }})</span>
              </template>

              <template v-slot:deleted="{ item }">
                      <span :class="item.deleted ? 'badge badge-soft-success' : 'badge badge-soft-danger'"
                            class="font-size-11">{{ $t(item.deleted ? 'message.yes' : 'message.no') }}</span>
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items">
                  <b-button variant="primary" class="btn-sm" @click="openInformationBarModal(item)">Edytuj</b-button>
                  <b-button variant="danger" class="btn-sm" @click="deleteInformationBar(item)">Usuń</b-button>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="modals.informationBar.visible"
        :title="modals.informationBar.form.id ? 'Edytowanie paska informacji' : 'Dodawanie nowego paska informacji'"
        title-class="font-18"
        hide-footer
        @hide="hideInformationBarModal"
        @esc="hideInformationBarModal">
      <div class="form-group">
        <label>Kolejność wyświetlania</label>
        <input v-model.number="modals.informationBar.form.orderValue" class="form-control"
               :class="{ 'is-invalid': submitted && $v.modals.informationBar.form.orderValue.$error }"/>
        <div v-if="submitted && !$v.modals.informationBar.form.orderValue.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Wiadomość</label>
        <textarea v-model="modals.informationBar.form.message" class="form-control"
                  :class="{ 'is-invalid': submitted && $v.modals.informationBar.form.message.$error }"/>
        <div v-if="submitted && !$v.modals.informationBar.form.message.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Kolor tła</label>
        <b-form-input v-model="modals.informationBar.form.backgroundColorHex" type="color"
                      :class="{ 'is-invalid': submitted && $v.modals.informationBar.form.backgroundColorHex.$error }"/>
        <div v-if="submitted && !$v.modals.informationBar.form.backgroundColorHex.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Kolor tekstu</label>
        <b-form-input v-model="modals.informationBar.form.textColorHex" type="color"
                      :class="{ 'is-invalid': submitted && $v.modals.informationBar.form.textColorHex.$error }"/>
        <div v-if="submitted && !$v.modals.informationBar.form.textColorHex.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Data rozpoczęcia</label>
        <b-form-input v-model="modals.informationBar.form.startTime" id="date-time" type="datetime-local"
                      :class="{ 'is-invalid': submitted && $v.modals.informationBar.form.startTime.$error }"/>
        <div v-if="submitted && !$v.modals.informationBar.form.startTime.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>Data zakończenia</label>
        <b-form-input v-model="modals.informationBar.form.endTime" type="datetime-local"
                      :class="{ 'is-invalid': submitted && $v.modals.informationBar.form.endTime.$error }"/>
        <div v-if="submitted && !$v.modals.informationBar.form.endTime.required" class="invalid-feedback">
          {{ $t('message.required') }}
        </div>
      </div>

      <div class="text-right">
        <b-button @click="createOrEditInformationBar" variant="success">
          {{ modals.informationBar.form.id ? 'Edytuj' : 'Utwórz' }}
        </b-button>
        <b-button class="ml-1" variant="danger" @click="hideInformationBarModal">{{ $t('message.cancel') }}</b-button>
      </div>
    </b-modal>

  </Layout>
</template>

<style scoped>
.information-bar-circle {
  width: 20px;
  height: 20px;
}
</style>